@use "sass:meta";

$background-color: null !default;
$base-font-family: null !default;
$base-font-size: null !default;
$base-font-weight: null !default;
$base-line-height: null !default;
$code-font-family: null !default;
$content-width: null !default;
$embedded-code-accent-color: null !default;
$embedded-code-background-color: null !default;
$embedded-code-text-color: null !default;
$footer-background-color: null !default;
$footer-border-color: null !default;
$footer-height: null !default;
$footer-link-color: null !default;
$footer-text-color: null !default;
$header-background-color: null !default;
$header-border-color: null !default;
$header-font-family: null !default;
$header-height: null !default;
$header-title-width: null !default;
$highlight-accent-color: null !default;
$highlight-background-color: null !default;
$hyde-primary: null !default;
$icon-border-color: null !default;
$icon-fill-color: null !default;
$icon-hover-border-color: null !default;
$icon-hover-primary-color: null !default;
$icon-hover-secondary-color: null !default;
$icon-hover-text-color: null !default;
$icon-primary-color: null !default;
$icon-secondary-color: null !default;
$icon-text-color: null !default;
$inline-code-accent-color: null !default;
$inline-code-background-color: null !default;
$inline-code-text-color: null !default;
$link-color: null !default;
$on-laptop: null !default;
$on-palm: null !default;
$post-meta-text-color: null !default;
$quote-accent-color: null !default;
$quote-background-color: null !default;
$quote-text-color: null !default;
$site-grey-color-dark: null !default;
$site-grey-color-light: null !default;
$site-grey-color: null !default;
$site-menu-background-color: null !default;
$site-menu-border-color: null !default;
$site-menu-fill-color: null !default;
$site-nav-link-color: null !default;
$small-font-size: null !default;
$spacing-unit: null !default;
$table-separator-color: null !default;
$text-color: null !default;

/**
 * Site header
 */
.site-header {
  background-color: $header-background-color;
  border-bottom: 1px solid $header-border-color;
  min-height: $header-height;
  margin-bottom: 10px;
  padding-bottom: 14px;
  padding-top: 16px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

$header-image: null !default;

.site-title {
  float: left;
  width: $header-title-width;
  min-height: $header-height;

  @if $header-image != null {
    background-image: $header-image;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.site-nav {
  float: right;
  line-height: $header-height;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $site-nav-link-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  // @include media-query($on-palm) {
  //   position: absolute;
  //   top: 12px;
  //   right: 12px;
  //   background-color: $site-menu-background-color;
  //   border: 1px solid $site-menu-border-color;
  //   border-radius: 5px;
  //   text-align: right;

  //   .menu-icon {
  //     display: block;
  //     float: right;
  //     width: 36px;
  //     height: 26px;
  //     line-height: 0;
  //     padding-top: 10px;
  //     text-align: center;

  //     > svg {
  //       width: 18px;
  //       height: 15px;

  //       path {
  //         fill: $site-menu-fill-color;
  //       }
  //     }
  //   }

  //   .trigger {
  //     clear: both;
  //     display: none;
  //   }

  //   &:hover .trigger {
  //     display: block;
  //     padding-bottom: 5px;
  //   }

  //   .page-link {
  //     display: block;
  //     padding: 5px 10px;
  //     margin-left: 20px;

  //     &:not(:last-child) {
  //       margin-right: 0;
  //     }
  //   }
  // }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $footer-border-color;
  padding: 20px 0;
  background-color: $footer-background-color;
}

.footer-heading {
  font-size: $base-font-size;
  margin-bottom: 0;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-table {
  font-size: $small-font-size;
  color: $footer-text-color;
  width: 100%;

  > tbody {
    > tr {
      > td {
        > a {
          color: $footer-link-color;
        }
      }
    }
  }
}

.footer-left {
  text-align: left;
  vertical-align: top;
  width: 33%;
}

.footer-center {
  text-align: center;
  vertical-align: top;
  width: 34%;
}

.footer-right {
  text-align: right;
  vertical-align: top;
  width: 33%;
}

/**
 * Main content
 */
// Make footer "sticky". I.e., push to bottom of page when there is little content,
// and push to the end of content otherwise.
.main-content {
  min-height: 100%;
  margin-bottom: -($footer-height);
  background-color: $background-color;
}

.main-content:after {
  content: "";
  display: block;
}

.site-footer,
.main-content:after {
  height: $footer-height;
  box-sizing: border-box;
}

/**
 * Top bar
 */
.top-bar {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  // @include media-query($on-palm) {
  //   flex-direction: column;
  // }
}

.top-bar-breadcrumb {
  flex: 0 0 auto;
}

.top-bar-spacer {
  flex: 1 1 auto;
}

.top-bar-theme {
  flex: 0 0 auto;
}

/**
 * Breadcrumbs
 */
.breadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline;
  }

  > li.active {
    font-style: oblique;
  }

  > li.crumb:before {
    content: "\2009|\a0";
  }
}

/**
 * Page content
 */
.page-content {
  padding: (calc($spacing-unit / 2)) 0;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $post-meta-text-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: calc($spacing-unit / 2);

  > .code {
    font-family: $code-font-family;
  }
}

.post-title {
  font-size: 2.5em;

  // @include media-query($on-laptop) {
  //   font-size: 2.3em;
  // }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1 {
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 10px;

    // @include media-query($on-laptop) {
    //   font-size: 1.8em;
    // }
  }

  h2 {
    font-size: 1.8em;

    // @include media-query($on-laptop) {
    //   font-size: 1.6em;
    // }
  }

  h3 {
    font-size: 1.6em;

    // @include media-query($on-laptop) {
    //   font-size: 1.4em;
    // }
  }

  h4 {
    font-size: 1.4em;

    // @include media-query($on-laptop) {
    //   font-size: 1.2em;
    // }
  }

  h5 {
    font-size: 1.2em;

    // @include media-query($on-laptop) {
    //   font-size: 1em;
    // }
  }
}

/**
 * Tables
 */

table {
  border-collapse: collapse;
}

.indent {
  margin-left: calc($spacing-unit / 2);
}

/**
 * Definition tables are indented on the left, and have horizontal lines between
 * every row.
 */
.definition-table {
  margin-left: calc($spacing-unit / 2);
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $table-separator-color;
  border-top: 1px solid $table-separator-color;
  width: 100%;

  > tbody {
    > tr {
      border-top: 1px solid $table-separator-color;

      > th {
        text-align: left;
      }

      > th:not(:first-child) {
        padding-left: 1em;
      }

      > td {
        vertical-align: top;
        > p {
          margin: 0;
          display: inline;
        }
      }

      > td:not(:first-child) {
        padding-left: 1em;
      }

      > td.type {
        font-family: $code-font-family;
        white-space: nowrap;
        vertical-align: top;
      }

      > td.field {
        font-family: $code-font-family;
        white-space: nowrap;
        vertical-align: top;
      }

      > td.decl {
        font-family: $code-font-family;
        white-space: nowrap;
        vertical-align: top;
      }

      > td.defn {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}

/**
 * Associated tables are those that enumerate library subcomponents and
 * associated elements.
 */
.associated-table {
  margin-left: calc($spacing-unit / 2);
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $table-separator-color;
  border-top: 1px solid $table-separator-color;
  width: 100%;

  > tbody {
    > tr {
      border-top: 1px solid $table-separator-color;

      > th {
        text-align: left;
      }

      > td {
        > p {
          margin: 0;
          display: inline;
        }
      }

      > td.name {
        text-indent: -3em;
        padding-left: 3em;
        font-family: $code-font-family;

        // white-space: nowrap;
        vertical-align: top;
        padding-right: 1.5em;
      }

      > td.brief {
      }
    }
  }
}

/**
 * Declaration tables are identical to definition tables, but without the
 * indent.
 */
.declaration-table {
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $table-separator-color;
  border-top: 1px solid $table-separator-color;
  width: 100%;

  > tbody {
    > tr {
      border-top: 1px solid $table-separator-color;

      > th {
        text-align: left;
      }

      > td {
        > p {
          margin: 0;
          display: inline;
        }
      }

      > td.decl {
        font-family: $code-font-family;
        white-space: nowrap;
        vertical-align: top;
        padding-right: 1.5em;
      }

      > td.defn {
      }
    }
  }
}

/**
 * State transition tables, like the one in the transaction docs.
 */
.state-transition {
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $table-separator-color;
  border-top: 1px solid $table-separator-color;
  width: 100%;

  > thead {
    > tr {
      border-bottom: 1px solid $table-separator-color;
    }
  }

  > tbody {
    > tr {
      border-top: 1px solid $table-separator-color;

      > td {
      }
    }
  }
}

/**
 * Parameter Notes, like the one in the collection connect_insert docs.
 */
.parameter-table {
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $table-separator-color;
  border-top: 1px solid $table-separator-color;
  width: 100%;

  > thead {
    > tr {
      border-bottom: 1px solid $table-separator-color;

      > th {
        font-weight: bold;
        text-align: left;
      }
    }
  }

  > tbody {
    > tr {
      border-top: 1px solid $table-separator-color;
      border-spacing: 0.1em;

      > td {
        vertical-align: top;
        padding-right: 0.5em;
      }
    }
  }
}

/**
 * Directory tables, like the ones that structure the `eng` folder.
 */
.directory-table {
  margin-left: 1em;

  > tbody {
    > tr {
      > td {
        padding-right: 0.2em;
        line-height: 1em;
        vertical-align: top;
      }
    }
  }
}

/**
 * Description list for method descriptions
 */
.description-list {
  > li {
    padding-bottom: 0.5em;

    > p {
      margin: 0;
      display: inline;
    }
  }
}

/**
 * Annotations.
 */
span.annotation {
  color: green;
  font-style: italic;
  font-size: 0.75em;
}

/**
 * Parameter tables are identical to definition tables, but without the
 * horizontal lines.
 */
.parameter-table {
  margin-left: calc($spacing-unit / 2);
  margin-bottom: calc($spacing-unit / 2);
  width: 100%;

  > tbody {
    > tr {
      > th {
        text-align: left;
      }

      > td {
        vertical-align: top;

        > p {
          margin: 0;
          display: inline;
        }
      }

      > td.decl {
        font-family: $code-font-family;
        padding-right: 1.5em;
      }

      > td.defn {
      }
    }
  }
}

/**
 * Example tables are indented on the left, and have horizontal lines between
 * every row.
 */
.example-table {
  margin-bottom: calc($spacing-unit / 2);
  border-bottom: 1px solid $table-separator-color;
  border-top: 1px solid $table-separator-color;
  width: 100%;

  > tbody {
    > tr {
      border-top: 1px solid $table-separator-color;

      > th {
        text-align: left;
      }

      > td {
        vertical-align: top;

        > p {
          margin: 0;
          display: inline;
        }
      }

      > td.decl_func {
        font-family: $code-font-family;
      }

      > td.decl_link {
        font-family: $code-font-family;
        padding-left: 1.5em;
      }

      > td.defn {
        padding-left: 1.5em;
      }
    }
  }
}

/**
 * Contributors listed in the About page
 */
.contributors-table {
  > tbody {
    > tr {
      > td {
        padding-left: 1.5em;
        padding-bottom: 1em;

        > img {
          width: 5em;
          height: 5em;
        }
      }
    }
  }
}

/**
 * Styling for some of the auto generated stuff
 */

.declaration-list {
  > p {
    display: inline;
  }
}

/**
 * Front page iconography
 */
.icon-table {
  margin: auto;
  border-collapse: separate;
  border-spacing: 3em 0;

  > tbody {
    > tr {
      > td {
        > a {
          text-decoration: none;
        }
      }
    }
  }

  // @include media-query($on-palm) {
  //   border-spacing: 1em 0;
  // }
}

.icon-box {
  background: $icon-primary-color;
  color: $icon-text-color;
  border-radius: 1em;

  // @include media-query($on-palm) {
  //   border-radius: 0.3em;
  // }

  > .top {
    border-radius: 1em 1em 0 0;

    // @include media-query($on-palm) {
    //   border-radius: 0.3em 0.3em 0 0;
    // }

    > .fa {
      display: inline-block;
      width: 1.7em;
      text-align: center;
      line-height: 1.4em;
      font-size: 5em;
      vertical-align: middle;

      // @include media-query($on-palm) {
      //   font-size: 2em;
      // }
    }
  }

  > .bottom {
    background: $icon-secondary-color;
    border-radius: 0 0 1em 1em;
    border-left: 1px solid $icon-border-color;
    border-right: 1px solid $icon-border-color;
    border-bottom: 1px solid $icon-border-color;
    font-weight: bold;
    text-align: center;
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    // @include media-query($on-palm) {
    //   padding-top: 0.2em;
    //   padding-bottom: 0.2em;
    //   border-radius: 0 0 0.3em 0.3em;
    // }
  }

  &:hover {
    background: $icon-hover-primary-color;
    color: $icon-hover-text-color;

    > .bottom {
      background: $icon-hover-secondary-color;
      border-left: 1px solid $icon-hover-border-color;
      border-right: 1px solid $icon-hover-border-color;
      border-bottom: 1px solid $icon-hover-border-color;
    }
  }
}

.rss-subscribe {
  text-align: right;
}

/**
 * Dialogs, for lack of a better name
 */

.warning-box {
  margin: 0;

  //1em;
  margin-bottom: calc($spacing-unit / 2);
  padding: 0.5em 1em;
  border-radius: 0.5em;
  border: 1px solid #f0f000;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9f977+0,fcfccf+100 */
  background: #f9f977;

  /* Old browsers */
  background: -moz-linear-gradient(top, #f9f977 0%, #fcfccf 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f9f977 0%, #fcfccf 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f9f977 0%, #fcfccf 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f977', endColorstr='#fcfccf',GradientType=0 );

  /* IE6-9 */
  &:before {
    content: "Warning: ";
  }
}
