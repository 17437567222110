/* ---
# Only the main Sass file needs front matter (the dashes are enough)
--- */
@charset "utf-8";

// Theme variables are split into two buckets: primaries and dependents. First we define the
// primary variables, then allow the end-user to override them by defining their own override
// file set. Finally, we derive the dependent variables from the primaries, taking into account
// any user overrides that have been made.

$background-color: white;
$base-font-family: "Source Serif Pro",serif;
$base-font-size: 12pt;
$base-font-weight: 400;
$base-line-height: 1.25;
$code-font-family: "Source Code Pro",monospace;
$content-width: 1000px;
$footer-background-color: #e4e4e4;
$footer-border-color: transparent;
$footer-height: 100px;
$header-background-color: #e4e4e4;
$header-border-color: transparent;
$header-font-family: "Source Sans Pro",sans-serif;
$highlight-accent-color: #dbdbdb;
$highlight-background-color: #f0f0f0;
$hyde-primary: blue;
$icon-hover-secondary-color: white;
$icon-secondary-color: white;
$on-laptop: 800px;
$on-palm: 600px;
$site-grey-color: #949597;
$site-menu-background-color: inherit;
$site-menu-border-color: transparent;
$spacing-unit: 30px;
$text-color: #333;
$header-title-width: 325px;
