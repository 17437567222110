@use "sass:string";

// Variables passed in from root.scss
$background-color: null !default;
$base-font-family: null !default;
$base-font-size: null !default;
$base-font-weight: null !default;
$base-line-height: null !default;
$code-font-family: null !default;
$content-width: null !default;
$embedded-code-accent-color: null !default;
$embedded-code-background-color: null !default;
$embedded-code-text-color: null !default;
$footer-background-color: null !default;
$footer-border-color: null !default;
$footer-height: null !default;
$footer-link-color: null !default;
$footer-text-color: null !default;
$header-background-color: null !default;
$header-border-color: null !default;
$header-font-family: null !default;
$header-height: null !default;
$header-title-width: null !default;
$highlight-accent-color: null !default;
$highlight-background-color: null !default;
$hyde-primary: null !default;
$icon-border-color: null !default;
$icon-fill-color: null !default;
$icon-hover-border-color: null !default;
$icon-hover-primary-color: null !default;
$icon-hover-secondary-color: null !default;
$icon-hover-text-color: null !default;
$icon-primary-color: null !default;
$icon-secondary-color: null !default;
$icon-text-color: null !default;
$inline-code-accent-color: null !default;
$inline-code-background-color: null !default;
$inline-code-text-color: null !default;
$link-color: null !default;
$on-laptop: null !default;
$on-palm: null !default;
$post-meta-text-color: null !default;
$quote-accent-color: null !default;
$quote-background-color: null !default;
$quote-text-color: null !default;
$site-grey-color-dark: null !default;
$site-grey-color-light: null !default;
$site-grey-color: null !default;
$site-menu-background-color: null !default;
$site-menu-border-color: null !default;
$site-menu-fill-color: null !default;
$site-nav-link-color: null !default;
$small-font-size: null !default;
$spacing-unit: null !default;
$table-separator-color: null !default;
$text-color: null !default;

/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

/**
 * Basic styling
 */
body {
  font: #{$base-font-weight} #{$base-font-size}/#{$base-line-height}
    $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}

pre,
code {
  // font: $base-font-weight #{$base-font-size}/#{$base-line-height} $code-font-family;
  font-family: $code-font-family;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
ul,
ol,
dl,
p,
figure %vertical-rhythm {
  margin-bottom: calc($spacing-unit / 2);
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

/**
 * Lists
 */
ul,
ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: $base-font-weight;
}

/**
 * Links
 */
a {
  color: $link-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/**
 * Blockquotes
 */
blockquote {
  color: $quote-text-color;
  background-color: $quote-background-color;
  border-left: 4px solid $quote-accent-color;
  padding-left: calc($spacing-unit / 2);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;

  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Code formatting
 */

dt {
  font-weight: bold;
  margin-top: 8px;
}

dd {
  margin-left: 8px;
}

pre {
  // padding: 8px 0px 8px 0px;
  overflow-x: auto;
  margin: 0;

  > code {
    line-height: 0.8;
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: string.unquote(
    "#{$content-width} - #{$spacing-unit} * 2"
  );
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;

  @extend %clearfix;

  // @include media-query($on-laptop) {
  //   max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
  //   max-width: unquote("#{$content-width} - #{$spacing-unit}");
  //   padding-right: calc($spacing-unit / 2);
  //   padding-left: calc($spacing-unit / 2);
  // }
}

/**
 * Clearfix
 */
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/**
 * Icons
 */
.icon {
  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $icon-fill-color;
    }
  }
}
